/* You can add global styles to this file, and also import other style files */
@import "primeicons/primeicons.css";
@import "primeflex/primeflex.css";

@import "primeng/resources/themes/lara-light-indigo/theme.css";
@import "primeng/resources/primeng.min.css";

/* Import PrimeNG CSS */
@import "primeflex/primeflex.css";
@import "primeng/resources/themes/lara-light-indigo/theme.css";
@import "primeng/resources/primeng.min.css";
@import "primeicons/primeicons.css";

/* Global Styles */


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.pointer {
    cursor: pointer;
}

/* Forms */
.container {
  font-family: Arial, sans-serif;
  padding: 2rem;
}


/* Header */
.header-container {
  border: 1px solid #000;
}

.border-right {
  border-right: 1px solid #000;
}

.border-left {
  border-left: 1px solid #000;
}

.border-horizontal-center {
  border-right: 1px solid #000;
  border-left: 1px solid #000;
}

.logo-img {
  width: 50%; /* Ajusta según el tamaño necesario */
  height: auto;
}

.title {
  font-size: 1.1rem;
  font-weight: bold;
  margin: 0;
}

.subtitle {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
}

.text-center {
  text-align: center;
}


.p-breadcrumb {
    background: transparent!important;
    border: none!important;
    border-radius: 6px;
    padding: 1rem;
}

 /* consents */
.p-card .p-card-content {
  padding: 1.50rem 0;
}

#gender-dropdown, #registrationDate, #relationship-dropdown, #acting-as-dropdown, #acting-as-dropdown-advance, #identificationType-dropdown, #patientIdentificationType-dropdown ,#chooseForm {
    border-top: none;
    border-left: none;
    border-right: none;
    box-shadow: none;
    border-bottom: none;
    border-radius: 0%;
    .p-inputtext {
        padding: 0.5rem 0;
    }
}

#acting-as-dropdown {
  width: 24%;
  .p-inputtext {
 margin-bottom: 0.5rem;
}
}


#registrationDate, #withdrawalRegistrationDate, .p-inputtext {
  border: none;
  border-bottom: 2px solid #2296D3;
  border-radius: 0%;
  outline: none;
  width: 100%;
  padding: 0.5rem 0;
}



 /* forms */
 .p-error {
    color: #dc3545;
    font-size: 0.875rem;
    margin-top: 0.25rem;
    display: block;
  }

   /* Estilo para campos inválidos */
  .ng-invalid.ng-dirty {
    border-bottom: solid 2px #dc3545;
    
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(220,53,69,.25);
    }
  }

 /* Estilo para el checkbox inválido */
  p-checkbox.ng-invalid.ng-dirty {
    .p-checkbox-box {
      border-color: #dc3545;
      
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(220,53,69,.25);
      }
    }
  }

.header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  /* form {
    padding: 2rem 0 0 0;
  } */
  
  .calendar-label {
      font-size: 0.9rem;
      font-weight: bold;
      margin-bottom: 0.5rem;
  }
  
  /* Clase personalizada para aplicar solo el borde inferior */
.input-underline {
  border: none;               /* Elimina todos los bordes */
  border-bottom: 2px solid #2296D3; /* Agrega solo el borde inferior */
  border-radius: 0;           /* Remueve cualquier borde redondeado */
  outline: none;              /* Evita el borde azul al hacer clic */
  width: 100%;                /* Asegura que el input ocupe todo el espacio del contenedor */
  padding: 0.5rem 0;          /* Añade algo de padding vertical */
  transition: border-color 0.3s; /* Transición suave para el borde */
}

/* Estilo al hacer foco en el input */
.input-underline:focus {
  border-bottom: 2px solid #6ae64b; /* Cambia el color del borde inferior al hacer foco */
}

#acting-as-dropdown-advance {
  min-width: 30%!important;
  
}


.formgroup-inline {
  margin: 0;
  padding: 0;
}

.header h1 {
  font-size: 1.5rem;
  font-weight: bold;
}

.header p {
  margin: 0.5rem 0;
}

.divider-row {
  height: 1.5rem;
}

.divider-column-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; /* Asegura que el contenido ocupe toda la altura de la columna */
  text-align: center; /* Centra el texto dentro de la columna */
}


.form-group {
  margin-bottom: 1rem;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

input, p-dropdown {
  width: 100%;
}

button[type="submit"] {
  display: block;
  width: 100%;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}

button[type="submit"]:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.table-container {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 1rem;
  border: 1px solid #ccc;
  text-align: left;
}

th {
  background-color: #f4f4f4;
  font-weight: bold;
} 

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}


@media screen and (max-width: 768px) {
  #acting-as-dropdown {
    width: 74%;
  }
}