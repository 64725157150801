/* This project is developed by [Derwing Medina](https://derwing-portfolio.netlify.app/) for IPS Nueva Popayan */
:root {
  /* Base colors */
  --pantone-294c: #002d72; /* Dark blue */
  --pantone-2945c: #005bac; /* Medium blue */
  --pantone-2925c: #00b0f0; /* Bright blue */
  --pantone-2728c: #0047bb; /* Vivid blue */

  /* Neutral shades */
  --neutral-black: #1a1a1a1a; /* Deep neutral black */
  --neutral-white: #ffffff; /* Pure white */
  --neutral-gray-light: #f5f5f5f5; /* Light gray */
  --neutral-gray-medium: #cccccccc; /* Medium gray */
  --neutral-gray-dark: #666666; /* Dark gray */

  /* Complementary and accent colors */
  --complementary-orange: #ff7f32; /* Complementary orange */
  --complementary-yellow: #ffd700; /* Golden-yellow */
  --accent-coral: #ff6f61; /* Coral */
  --accent-lime: #32cd32; /* Lime green */
  --accent-light-blue: #e0f7fa; /* Pastel light-blue */
  --accent-dark-blue: #003366; /* Dark blue accentuated */

  /* Base tone variations */
  --light-pantone-294c: #335a99; /* Lighter variant of dark blue */
  --dark-pantone-294c: #001b45; /* Darker variant of dark blue */
  
  --light-pantone-2945c: #3380cb; /* Lighter variant of medium blue */
  --dark-pantone-2945c: #00396d; /* Darker variant of medium blue */
  
  --light-pantone-2925c: #66cfff; /* Lighter variant of bright blue */
  --dark-pantone-2925c: #0088cc; /* Darker variant of brilliant blue */
  
  --light-pantone-2728c: #336fcf; /* Lighter variant of bright blue */
  --dark-pantone-2728c: #002b80; /* Darker variant of vivid blue */
}
